<div>
  <h5 *ngIf="type == 'challenge'">
    Challenge:
    {{
      statementResponse?.challengestatement?.title || statementResponse?.title
    }}
  </h5>
  <h5 *ngIf="type == 'ideapitch'">
    Idea Pitch:
    {{
      statementResponse?.challengestatement?.title || statementResponse?.title
    }}
  </h5>
  <div class="row mb-2">
    <div
      class="col-sm-12"
      style="display: flex; align-items: center; color: #e5860a"
    >
      <h6 class="m-0">
        Provider: {{ statementResponse?.vendor?.name }} | Sector:
        {{
          statementResponse?.vendor?.sector || statementResponse?.vendor?.sector
        }}
        | Type:
        {{ statementResponse?.vendor?.type || statementResponse?.vendor?.type }}
      </h6>
      <br />
    </div>
  </div>

  <mat-horizontal-stepper
    labelPosition="bottom"
    #stepper
    id="setupStep"
    style="height: 67%"
    (selectionChange)="selectedStepper($event)"
  >
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="home">
      <mat-icon>home</mat-icon>
    </ng-template>

    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>
          Commercial & Business <br />
          Effort/Cost/ROI/Payback</ng-template
        >
        <div class="row mt-3">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-25 px-1">
              <mat-label>Effort(in man hours)</mat-label>
              <input matInput formControlName="effort" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-25 px-1">
              <mat-label>Blended Cost</mat-label>
              <input matInput formControlName="cost" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50 px-1">
              <mat-label>ROI</mat-label>
              <mat-select formControlName="roi">
                <mat-option *ngFor="let roi of roiValue" [value]="roi.id">{{
                  roi?.display_name
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-25 px-1">
              <mat-label>Payback Unit</mat-label>
              <mat-select formControlName="payback_time_unit">
                <mat-option value="Month">Month</mat-option>
                <mat-option value="Year">Year</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-25 px-1">
              <mat-label>Payback Time</mat-label>
              <mat-select formControlName="payback_time">
                <mat-option
                  *ngFor="let item of [].constructor(12); let i = index"
                  [value]="i + 1"
                  >{{ i + 1 }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-50 px-1">
              <mat-icon matPrefix>attach_money</mat-icon>
              <mat-label>Payback Value</mat-label>
              <input matInput formControlName="payback_value" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100 px-1">
              <mat-label>Justification</mat-label>
              <textarea
                matInput
                rows="4"
                formControlName="commercial_justification"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div
            class="col-sm-6 ml-auto d-flex justify-content-end"
            *ngIf="
              acceratorDetails?.accelerator?.status != 'create_turnkey' &&
              acceratorDetails?.accelerator?.status != 'create_opportunity'
            "
          >
            <button
              mat-stroked-button
              mat-button
              color="warn"
              (click)="rejectResponse()"
              class="mr-1"
            >
              Reject
            </button>
            <button
              mat-flat-button
              mat-button
              color="primary"
              class="bg-primary text-white ml-1"
              (click)="saveCommercial($event)"
              matStepperNext
            >
              Approved
            </button>
          </div>
          <div
            class="col-sm-6 ml-auto d-flex justify-content-end"
            *ngIf="
              acceratorDetails?.accelerator?.status == 'create_turnkey' ||
              acceratorDetails?.accelerator?.status == 'create_opportunity'
            "
          >
            <button
              mat-flat-button
              mat-button
              color="primary"
              class="bg-primary text-white ml-1"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>
          Draw down <br />
          Investor Selection</ng-template
        >
        <div class="row mt-3">
          <div class="col-sm-12">
            <mat-form-field
              appearance="outline"
              class="w-100 px-1"
              *ngIf="statementResponse?.vendor?.investments?.length > 0"
            >
              <mat-label>Select Vendor Investment Type</mat-label>
              <mat-select formControlName="investment_type_id">
                <mat-option
                  *ngFor="
                    let item of statementResponse?.vendor?.investments;
                    let i = index
                  "
                  [value]="item.id"
                  >{{ item.description }}</mat-option
                >
              </mat-select>
              <mat-icon matSuffix (click)="openInfo(); $event.stopPropagation()"
                >info_outline</mat-icon
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100 px-1">
              <mat-label>Justification</mat-label>
              <textarea
                matInput
                rows="4"
                formControlName="investor_justification"
              ></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div
            class="col-sm-6 ml-auto d-flex justify-content-end"
            *ngIf="
              acceratorDetails?.accelerator?.status != 'create_turnkey' &&
              acceratorDetails?.accelerator?.status != 'create_opportunity'
            "
          >
            <button
              mat-stroked-button
              mat-button
              color="warn"
              (click)="rejectResponse()"
              class="mr-1"
            >
              Reject
            </button>
            <button
              mat-flat-button
              mat-button
              color="primary"
              class="bg-primary text-white ml-1"
              (click)="saveDrawDown()"
              matStepperNext
            >
              Approved
            </button>
          </div>
          <div
            class="col-sm-6 ml-auto d-flex justify-content-end"
            *ngIf="
              acceratorDetails?.accelerator?.status == 'create_turnkey' ||
              acceratorDetails?.accelerator?.status == 'create_opportunity'
            "
          >
            <button
              mat-flat-button
              mat-button
              color="primary"
              class="bg-primary text-white ml-1"
              matStepperNext
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel
        >Finalize <br />
        Go for implentation</ng-template
      >
      <div
        class="p-3"
        *ngIf="
          acceratorDetails?.accelerator?.status != 'create_turnkey' &&
          acceratorDetails?.accelerator?.status != 'create_opportunity'
        "
        style="
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 300px;
        "
      >
        <div class="carts" (click)="add(statementResponse?.challengestatement)">
          <p class="m-0">
            Create <br />
            Opportunity
          </p>
        </div>
        <div
          class="carts"
          style="background: #56d372"
          (click)="createTurnKey()"
        >
          <p class="m-0">
            Create <br />
            Plan
          </p>
        </div>
      </div>
      <div
        class="p-3"
        *ngIf="acceratorDetails?.accelerator?.status == 'create_turnkey'"
        style="
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <div
          style="
            padding: 20px;
            background: #d2eb9e;
            border-radius: 15px;
            font-size: 14px;
            width: 300px;
            text-align: center;
          "
        >
          <p class="m-0">Plan Center Info</p>
          <hr />
          <p style="font-size: 16px; font-weight: 600">
            {{ acceratorDetails?.details?.name }}
          </p>
          <!-- <p class="m-0">Status: <b>Under Progress</b></p>
                    <p class="m-0">Updated on: <b>29 Aug 2023</b></p> -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding-top: 25px;
            "
          >
            <span
              >Phases:
              <b>{{ acceratorDetails?.details?.phases?.length }}</b></span
            >
            <span
              >Activity:
              <b>{{
                getActivityCount(acceratorDetails?.details?.phases)
              }}</b></span
            >
          </div>
          <button
            mat-flat-button
            color="view"
            class="mt-2"
            (click)="navigateTurnKey()"
          >
            View
          </button>
        </div>
      </div>
      <div
        class="p-3"
        *ngIf="acceratorDetails?.accelerator?.status == 'create_opportunity'"
        style="
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <div
          style="
            padding: 20px;
            background: #d2eb9e;
            border-radius: 15px;
            font-size: 14px;
            width: 300px;
            text-align: center;
          "
        >
          <p class="m-0">Opportunity Info</p>
          <hr />
          <p style="font-size: 16px; font-weight: 600" class="m-0">
            {{ acceratorDetails?.details[0]?.opportunity_name }}
          </p>
          <p class="m-0">
            {{ acceratorDetails?.details[0]?.opportunity_number }}
          </p>
          <!-- <p class="m-0">Status: <b>SOW/Signed</b></p> -->
          <!-- <p class="m-0">Updated on: <b>29 Aug 2023</b></p>
                    <div style="padding-top: 10px;">
                        <p class="m-0">Portfolio: <b>Channels Technology</b></p>
                        <p class="m-0">SubPortfolio: <b>Omnichnl Tech</b></p>
                        <p class="m-0">Program: <b>Fly Delta Mobile App - 1</b></p>
                    </div> -->
          <button
            mat-flat-button
            color="view"
            class="mt-2"
            (click)="navigate()"
          >
            View
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <!--    
    <div style="height: 100px; display: flex; align-items: center; font-size: 12px; padding: 0 30px; background-color: #FBFBFB; margin: 0 -25px">
        <div style="border-left: 1px solid #ded5d5; width: 30%; padding: 25px;">
            <span style="display: block;  padding-left: 13px; font-size: 10px; color: green;"><span *ngIf="step == 1">Current</span><span *ngIf="step > 1">Done</span></span>
            <span>1. &nbsp;Commercial & Business <br>
                &nbsp; &nbsp; Effort/Cost/ROI/Payback</span>
        </div>
        <div style="border-left: 1px solid #ded5d5; width: 30%; padding: 25px;">
            <span style="display: block;  padding-left: 13px; font-size: 10px; color: green;"><span *ngIf="step == 2">Current</span><span *ngIf="step > 2">Done</span><span *ngIf="step < 2">Upcoming</span></span>
            <span>2. &nbsp;Draw down<br>
                &nbsp; &nbsp; Investor Selection</span>
        </div>
        <div style="border-left: 1px solid #ded5d5; width: 30%; padding: 25px;">
            <span style="display: block;  padding-left: 13px; font-size: 10px; color: green;"><span *ngIf="step == 3">Current</span><span *ngIf="step > 3">Done</span><span *ngIf="step < 3">Upcoming</span></span>
            <span>3. &nbsp;Finalize<br>
                &nbsp; &nbsp; Go for implentation</span>
        </div>
    </div>
    <div class="p-3" *ngIf="step == 1">
        <div class="row mt-3">
            <div class="col-sm-12">
                <mat-form-field appearance="legacy" class="w-50 px-1" floatLabel="always">
                    <mat-label>Effort</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="w-50 px-1" floatLabel="always">
                    <mat-label>Cost</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="w-50 px-1" floatLabel="always">
                    <mat-label>ROI</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="w-50 px-1" floatLabel="always">
                    <mat-label>Payback</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="w-100 px-1" floatLabel="always">
                    <mat-label>Justification</mat-label>
                    <textarea matInput rows="4"></textarea>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row text-center">
            <div class="col-sm-6 mx-auto">
                <button mat-button color="reject" class="mr-1">Reject</button>
                <button mat-button color="approval"class="ml-1" (click)="nextStep(2)">Approved</button>
            </div>
        </div>
       
    </div>
    <div class="p-3" *ngIf="step == 2">
        <div class="row mt-3">
            <div class="col-sm-12">
                <mat-form-field appearance="legacy" class="w-100 px-1" floatLabel="always">
                    <mat-label>Select Vendor Investment Type</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="w-100 px-1" floatLabel="always">
                    <mat-label>Justification</mat-label>
                    <textarea matInput rows="4"></textarea>
                </mat-form-field>
            </div>
        </div>
        
        <div class="row text-center">
            <div class="col-sm-6 mx-auto">
                <button mat-button color="reject" class="mr-1">Reject</button>
                <button mat-button color="approval"class="ml-1" (click)="nextStep(3)">Approved</button>
            </div>
        </div>
       
    </div>
    <div class="p-3" *ngIf="step == 3  && !type" style="display: flex;  align-items: center; justify-content: space-around; height: 300px">
        <div class="carts" (click)="createOppo()">
            <p class="m-0">Create <br> Opportunity</p>
        </div>
        <div class="carts" style="background: #56D372" (click)="createTurnKey()">
            <p class="m-0">Create <br> Plan Center</p>
        </div>
       
    </div>
    <div class="p-3" *ngIf="step == 4 && type == 'turn'" style="display: flex;  align-items: center; justify-content: space-around; height: 300px">
        <div style="padding: 20px;  background: #D2EB9E; border-radius: 15px; font-size: 14px; 
        height: 250px;
        width: 300px;
        text-align: center;">
            <p style="font-size: 16px; font-weight: 600;">Plan Center Name</p>
            <p class="m-0">Status: <b>Under Progress</b></p>
            <p class="m-0">Updated on: <b>29 Aug 2023</b></p>
            <div style="display: flex; justify-content: space-between; padding-top: 75px;">
                <span>Phases: <b>3</b></span>
                <span>Activity: <b>10</b></span>
            </div>
            <button mat-button color="view" class="mt-2">View</button>
        </div>
       
    </div>
    <div class="p-3" *ngIf="step == 4 && type == 'oppo'" style="display: flex;  align-items: center; justify-content: space-around; height: 300px">
        <div style="padding: 20px;  background: #D2EB9E; border-radius: 15px; font-size: 14px; 
        height: 250px;
        width: 300px;
        text-align: center;">
            <p style="font-size: 16px; font-weight: 600;">Opportunity Sample Name </p>
            <p class="m-0">BET00023</p>
            <p class="m-0">Status: <b>SOW/Signed</b></p>
            <p class="m-0">Updated on: <b>29 Aug 2023</b></p>
            <div style="padding-top: 10px;">
                <p class="m-0">Portfolio: <b>Channels Technology</b></p>
                <p class="m-0">SubPortfolio: <b>Omnichnl Tech</b></p>
                <p class="m-0">Program: <b>Fly Delta Mobile App - 1</b></p>
            </div>
            <button mat-button color="view" class="mt-2">View</button>
        </div>
       
    </div>
</div> -->
</div>
