import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OperationsComponent } from './operations/operations.component';
import { ResponseDetailsComponent } from './response-details/response-details.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: "Delivery Accelerator" },
    children: [
      {
        path: '',
        redirectTo:'dashboard'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'operation/:type/:id/:acc_id',
        data: { breadcrumb: 'Operation' },
        component: OperationsComponent
      },
      {
        path: 'response-details/:id',
        data: { breadcrumb: 'Response Details' },
        component: ResponseDetailsComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryAcceleratorRoutingModule { }
