
<div>
    <h6>{{statementResponse?.challengestatement?.title}}</h6>
        <hr>
        <div class="row">
            <div class="col-sm-10" style="display: flex; align-items: center">
                <h5 class="m-0">{{statementResponse?.vendor?.name}} | {{statementResponse?.vendor?.sector}} | {{statementResponse?.vendor?.type}}</h5>
            </div>
        </div><br>
        <app-statement-response-details [resDetails]="statementResponse"></app-statement-response-details>
    <hr>
    <div class="p-3">
        <app-comments (dataEvent)="receiveDataFromComment($event)" (downloadFileEvent)="downloadCommentFile($event)" [comments]="responseComment" [refresh]="refreshComment" [noEditor]="'true'"></app-comments>
    </div>
</div>