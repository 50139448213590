import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { formatDistanceToNow } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ProgramService } from 'src/app/shared/services/program.service';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
@Input() accelerator:any;
@Input() underImplementation:any;
@Input() OpportunityFieldList:any;
@Input() type:any;
constructor(
    private router: Router,
    private dialog: MatDialog,
    private ps: ProgramService,
    
  ) { }

  ngOnInit(): void {
  }
  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 30%, 40%, 100)';
  } 
  navigateById(url:any,id:any,acc_id:any,status:any){
    if(status != 'rejected'){
      this.router.navigate([url+this.type+'/'+btoa(id)+'/'+btoa(acc_id)]);
    }
  }
  getLatestUpdated(dateTime:any){
    if(dateTime){
      const timestamp = new Date(dateTime);
      return formatDistanceToNow(timestamp, { addSuffix: true });
      }
      return '';
  }

  add(OpportunityName:any) {
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, width: '700px' });
    dialog.componentInstance.type = 'Addopperuntiy';
    dialog.componentInstance.OpportunityFieldList = this.OpportunityFieldList;
    dialog.componentInstance.OpportunityName = OpportunityName;
  }

  createTrunkey(){
    this.router.navigate(['confluence/teamplantracker']);
  }

  getName(name: any) {
    return name ? name.split(' ').slice(0, 2).map((n: any) => n[0]).join('') : name;
  }
}
