<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
  </div>
  <div class="bg-color p-3">

<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
      <div class="single-left-pane">
          <app-side-menu></app-side-menu>
      </div>
  </div>
  <div class="col-sm-7">
      <div class="single-center-pane">
        <router-outlet></router-outlet>
          
          
      </div>
  </div>
  <div class="col-sm-3">
      <div class="single-right-pane">
          <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
            <div *ngIf="csDetails">
              <h6>Statement</h6>
              <app-right-panel-statement-details *ngIf="csDetails" [role]="'prospect-provider'"></app-right-panel-statement-details>
              <br>
              <h5>Response</h5>
            </div>
            <div *ngIf="responseDetails">
              <div class="row">
                  <div class="col-sm-10" style="display: flex; align-items: center">
                    <h6 class="m-0">{{responseDetails?.vendor?.name || responseDetails?.vendor?.name}} | {{responseDetails?.vendor?.sector || responseDetails?.vendor?.sector}} | {{responseDetails?.vendor?.type || responseDetails?.vendor?.type}}</h6><br>
                  </div>
              </div><br>
              <app-statement-response-details [resDetails]="responseDetails"></app-statement-response-details>
              <!-- <hr>
              <div class="p-3">
                  <app-comments (dataEvent)="receiveDataFromComment($event)" *ngIf="commentDetails.length > 0" (downloadFileEvent)="downloadCommentFile($event)" [comments]="commentDetails" [refresh]="refreshComment" [noEditor]="'true'"></app-comments>
              </div> -->
            </div>
         
          <!-- <div *ngIf="!csDetails && !responseDetails">
            <h6>Insights & Engagement Zone</h6>
            <div class="comingSoon text-center">
                <img src="assets/images/analytics_bg.png" style="width: 100%; height: calc(100vh - 50vh); object-fit: cover; object-position: -236px; ">
                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
            </div>
          </div> -->
          
<!-- <div class="accordion-item" >
  <h2 class="accordion-header" id="headingZero">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero"
          style="font-size: 13px">
          Curated from Web
      </button>
  </h2>
  <div id="collapseZero" class="accordion-collapse collapse" aria-labelledby="headingZero"
      data-bs-parent="#accordionExample">
      <div class="accordion-body p-2">
          <app-loading *ngIf="loading"></app-loading>
          <app-gnews [display_header]="'true'"></app-gnews>
      </div>
  </div>
</div> -->
      <app-gnews [pagename]="'Delivery Accelerator - Challenge Workbench'"></app-gnews>
      </div>
  </div>
</div>

    <!-- <router-outlet></router-outlet> -->
  </div>
  <app-footer></app-footer>
  