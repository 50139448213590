import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-response-details',
  templateUrl: './response-details.component.html',
  styleUrls: ['./response-details.component.scss']
})
export class ResponseDetailsComponent implements OnInit {
  csResponseId:any;
  statementResponse:any;
  responseComment:any;
  refreshComment:boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
        private cw: ChallengeWorkbenchService,
        private spinner : NgxSpinnerService,
        private toastrService : ToastrService,
        
        
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((res: any) => {
      this.csResponseId = atob(res.get('id'));
      console.log( this.csResponseId );
      this.getChallengeStatementResponseById();
    });
  }
  getChallengeStatementResponseById(){
    this.spinner.show();
        this.cw.getChallengeStatementResponseById(this.csResponseId).subscribe((res:any)=>{
            this.statementResponse = res;
            this.statementResponse?.challengestatement?.vendor.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.statementResponse?.challengestatement?.problem_statement_okr.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.cw.setData(this.statementResponse?.challengestatement);
            this.spinner.hide();
            this.getResponseComment();
        });
  }
  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
}
getResponseComment(){
  this.cw.getResponseComments(this.csResponseId).subscribe((res:any)=>{
      this.responseComment = res;
  })
}
downloadCommentFile(event:any){
  this.spinner.show();
  this.cw.downloadCommentFile(event.id).subscribe((res: any) => {
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(res);
  downloadLink.setAttribute('download', event.file_name);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  this.toastrService.success('File downloaded successfully');
  this.spinner.hide();
  }, (error: any) => {
  this.spinner.hide();
  throw error;
  })
}
receiveDataFromComment(event:any){
  this.spinner.show();
  this.refreshComment = false;
  const formData = new FormData();
  if(event?.text || event?.document){
      if(event.text)
      formData.append('text',event.text);
      if(event.document){
      formData.append('document',event.document);
      }
      formData.append('response_id',this.csResponseId);
      formData.append('parent','');
      this.cw.postComments(formData).subscribe((res: any) => {
          this.responseComment.push(res);
          this.refreshComment = true;
          this.spinner.hide();
      })
  }   
}

ngOnDestroy() {
  this.cw.setData('');
}
}
