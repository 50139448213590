import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ProgramService } from 'src/app/shared/services/program.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';


@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {
  currentYear:any;
  @ViewChild('stepper') stepper: MatStepper|any;
  staticText: any = (textConfiguration as any).default;
  type: any;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  step: number = 1;
  csResponseId:any;
  statementResponse:any;
  responseComment:any;
  refreshComment:boolean = false;
  accId:any;
  acceratorDetails:any;
  OpportunityFieldList: any;
    portList: any[] = [];
    programList: any[] = [];
    breadcrumb:any;

    roiValue: any = [];
    investmentHistory: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cw: ChallengeWorkbenchService,
    private spinner : NgxSpinnerService,
    private toastrService : ToastrService,
    private dialog: MatDialog,
    private ps: ProgramService,
    private breadCrumService: BreadcrumbService,
  ) {
    this.route.paramMap.subscribe((res: any) => {
      this.csResponseId = atob(res.get('id'));
      this.accId = atob(res.get('acc_id'));
      this.type = res.get('type');
      this.getRoiBaseValue();
      this.getAcceleratorDetails();
      if(this.type=='ideapitch'){
        this.getIdeaPitchResponseDetails();
      }else{
        this.getChallengeStatementResponseById();
      }
    });
   }
  

  ngOnInit(): void {
    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: [''],
      effort:['', Validators.required],
      roi:['', Validators.required],
      cost:['', Validators.required],
      payback_time_unit:['', Validators.required],
      payback_time:['', Validators.required],
      payback_value:['', Validators.required],
      commercial_justification:['',Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: [''],
      investment_type_id: [null, this.statementResponse?.vendor?.investments > 0 ? Validators.required:''],
      investor_justification: ['', Validators.required],
    });

    this.currentYear=new Date().getFullYear();
    this.getOpportunity(); 
   
  }

  getRoiBaseValue(){
    this.cw.getRoiValue().subscribe((res:any)=>{
      this.roiValue = res;
    });
  }

  nextStep(s: any){
    this.step = s;
   // this.step = s;
  }

  createTurnKey(){
    this.step = 4;
    localStorage.setItem('accelerator_details', this.acceratorDetails.id);
    this.router.navigate(['confluence/teamplantemplates']);
  }

  createOppo(){
    this.step = 4;
    this.type = 'oppo';
  }
  getChallengeStatementResponseById(){
    this.spinner.show();
        this.cw.getChallengeStatementResponseById(this.csResponseId).subscribe((res:any)=>{
            this.statementResponse = res;
            this.statementResponse?.challengestatement?.vendor.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.statementResponse?.challengestatement?.problem_statement_okr.forEach((element:any) => {
                element.color = this.getGMapRandomColor();
            });
            this.cw.setData(this.statementResponse?.challengestatement);
            this.cw.setResponseData(this.statementResponse);
            this.spinner.hide();
            this.getResponseComment();
            let last = [
              {label:'Confluence' , url:'/delivery-accelerator/dashboard'},
              {label:'Delivery Accelerator' , url:'/delivery-accelerator/dashboard'},
              {label:this.statementResponse?.challengestatement?.title , url:'' }
            ]
             this.breadCrumService.setBreadcrumb(last);
        });
  }

  getIdeaPitchResponseDetails(){
    this.spinner.show();
    this.cw.getIdeaPitchResponseById(this.csResponseId).subscribe((res:any)=>{
      this.statementResponse = res;
      this.cw.setResponseData(this.statementResponse);
      let last = [
        {label:'Confluence' , url:'/delivery-accelerator/dashboard'},
        {label:'Delivery Accelerator' , url:'/delivery-accelerator/dashboard'},
        {label:this.statementResponse?.title , url:'' }
      ]
       this.breadCrumService.setBreadcrumb(last); 
       this.spinner.hide();
        
    });
  }
  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random() * 360) + ', 50%, 25%, 90%)';
  }
  getResponseComment(){
    this.cw.getResponseComments(this.csResponseId).subscribe((res:any)=>{
        this.responseComment = res;
        this.cw.setCommentData(this.responseComment);
    })
  }
  downloadCommentFile(event:any){
    this.spinner.show();
    this.cw.downloadCommentFile(event.id).subscribe((res: any) => {
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(res);
    downloadLink.setAttribute('download', event.file_name);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.toastrService.success('File downloaded successfully');
    this.spinner.hide();
    }, (error: any) => {
    this.spinner.hide();
    throw error;
    })
  }
  ngOnDestroy() {
    this.cw.setData('');
    this.cw.setCommentData('');
    this.cw.setResponseData('');
  }

  selectedStepper(event: any) : void{
    
  }

  rejectResponse(){
    this.spinner.show();
    let payload = {status:'rejected',source_type:this.type,accelerator :this.accId};
    if(this.acceratorDetails){
      this.cw.rejectAcceleratorPatchById(payload,this.acceratorDetails.id).subscribe((res:any)=>{
        this.router.navigate(['delivery-accelerator/dashboard']);
        this.spinner.hide();
      })
    }else{
      this.cw.rejectAcceleratorById(payload,this.accId).subscribe((res:any)=>{
        this.router.navigate(['delivery-accelerator/dashboard']);
        this.spinner.hide();
      })
    }
    
  }

  getAcceleratorDetails(){
    this.spinner.show();
    this.cw.getDeliveryAcceleratorById(this.accId).subscribe((res:any)=>{
      this.acceratorDetails = res;
      this.spinner.hide();
      this.patchResponse();
    })
  }

  patchResponse(){
    this.firstFormGroup.setValue({
      firstCtrl:'',
      effort:this.acceratorDetails.effort,
      roi: Number(this.acceratorDetails.roi),
      cost:this.acceratorDetails.cost,
      payback_time_unit:this.acceratorDetails.payback_time_unit,
      payback_time:this.acceratorDetails.payback_time,
      payback_value:this.acceratorDetails.payback_value,
      commercial_justification:this.acceratorDetails.commercial_justification
    });
    this.secondFormGroup.setValue({
      secondCtrl:'',
      investment_type_id: this.acceratorDetails?.investment_type?.id ? this.acceratorDetails?.investment_type?.id :null ,
      investor_justification:this.acceratorDetails.investor_justification
    })
  } 

  saveCommercial(event:any){
    this.firstFormGroup.markAllAsTouched();
    if(this.firstFormGroup.invalid){
      return;
    }
    if(!this.acceratorDetails){
      this.firstFormGroup.value.accelerator_id = this.accId;
      this.firstFormGroup.value.status = 'under_drawdown';
      this.cw.postCommercials(this.firstFormGroup.value).subscribe((res:any)=>{
        this.acceratorDetails = res;
      })
    }else{
      this.firstFormGroup.value.accelerator = this.accId;
      this.firstFormGroup.value.status = 'under_drawdown';
      this.cw.patchCommercials(this.firstFormGroup.value,this.acceratorDetails.id).subscribe((res:any)=>{
        this.acceratorDetails = res;
      })
    }
  }
  saveDrawDown(){
    this.secondFormGroup.markAllAsTouched();
    if(this.secondFormGroup.invalid){
      return;
    }
    if(!this.acceratorDetails){
      this.secondFormGroup.value.accelerator_id = this.accId;
      this.secondFormGroup.value.status = 'under_implementation';
      this.cw.postCommercials(this.secondFormGroup.value).subscribe((res:any)=>{
        this.acceratorDetails = res;
      })
    }else{
      this.secondFormGroup.value.accelerator_id = this.accId;
      this.secondFormGroup.value.status = 'under_implementation';
      this.cw.patchCommercials(this.secondFormGroup.value,this.acceratorDetails.id).subscribe((res:any)=>{
        this.acceratorDetails = res;
      })
    }
  }

  add(statement:any) {
    statement.details_id = this.acceratorDetails.id;
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, width: '700px' });
    dialog.componentInstance.type = 'Addopperuntiy';
    dialog.componentInstance.OpportunityFieldList = this.OpportunityFieldList;
    dialog.componentInstance.OpportunityName = statement;
  }

  getOpportunity() {
    this.ps.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
      this.OpportunityFieldList = res.records;
      this.OpportunityFieldList.map((i: any) => {
        if (i.field_name == "opportunity_portfolio") {
          this.portList = i.field_values;
        }
      })

      if(this.OpportunityFieldList.findIndex((e: any) => e.field_name == 'program_name') < 0 ){
        let programdata =  {
              "id": 1,
              "field_name": "program_name",
              "display_name": "Program Name",
              "lookup_field": "program_name",
              "field_type": "text-box",
              "field_status": true,
              "model_reference": "''",
              "field_values":this.programList,
              "form_details": {
                  "id": 1,
                  "form_name": "program_name"
              }
            }
          
          this.OpportunityFieldList.push(programdata);
      }
      // this.showOpportunityBtn = true;
      // this.createForm();
    }, error => {
     // this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
    this.ps.getProgramData().subscribe((res: any) => {
      this.programList = res;
     
    }, error => {
     // this.toastrService.error('Opportunity fields get failed', 'Error');
      throw error;
    })
  }

  openInfo(){
    this.cw.getInvestmentInfo(this.statementResponse.vendor.id).subscribe((res: any) => {
      this.investmentHistory = res;
      const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, width: '700px', data: this.investmentHistory });
      dialog.componentInstance.type = 'ShowInvestment';
    })
    
  }

  navigate(){
    this.router.navigate([`rfx/`+btoa(this.acceratorDetails.details[0].id)+`/opportunity/setup`]);
  }

  navigateTurnKey(){
    this.router.navigate([`confluence/plan/`+btoa(this.acceratorDetails.details.id)]);
  }

  getActivityCount(f: any){
    let actCount = 0;
    f.forEach((element: any) => {
      actCount = actCount + element.activities.length
    });
    return actCount;
  }
}
