import { Component, OnInit } from '@angular/core';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  currentYear:any;
  staticText: any = (textConfiguration as any).default;
  csDetails:any;
  responseDetails:any;
  commentDetails:any;
  loading:any;
  constructor(
    private cw : ChallengeWorkbenchService
  ) { }
  

  ngOnInit(): void {
    this.currentYear=new Date().getFullYear();
    this.cw.data$.subscribe((newData:any) => {
      this.csDetails = newData;
    });

    this.cw.dataResponse$.subscribe((newData)=>{
      this.responseDetails = newData;
    });

    this.cw.dataComment$.subscribe((newData)=>{
      this.commentDetails = newData;
    });

  }

}
