<div class="row">
  <div class="col-sm-12 trn-trc">
    <h5>Delivery Accelerator</h5>
  </div>
</div>
<app-loading *ngIf="!dataLoaded"></app-loading>
<div *ngIf="dataLoaded">
  <div class="filter-container my-2">
    <div class="filter-icon">
      <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
    </div>
    <input
      type="text"
      placeholder="Search Name..."
      class="form-control text-input"
      [(ngModel)]="filterName"
      (ngModelChange)="search()"
      style="width: 12rem; border-radius: 8px; font-size: 12px; height: 40px"
    />
    <mat-form-field
      class="filter-dropdown"
      appearance="outline"
      style="font-size: 12px"
    >
      <mat-select
        [(ngModel)]="filterVendor"
        (ngModelChange)="search()"
        placeholder="Search OU/GDP..."
      >
        <mat-option value=""></mat-option>
        <mat-option
          *ngFor="let vendor of vendorsList"
          value="{{ vendor.id }}"
          >{{ vendor.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <app-filter-card [pagetype]="'assessment-template'"></app-filter-card>
  </div>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Challenge Workbench">
      <ng-template matTabContent>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="All ({{ deliveryList?.challenge?.all?.count }})">
            <div *ngFor="let accelerator of deliveryList?.challenge?.all">
              <app-card
                [accelerator]="accelerator"
                [type]="'challenge'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Under Commercials  ({{
              deliveryList?.challenge?.underCommercials?.count
            }})"
          >
            <div
              *ngFor="
                let accelerator of deliveryList?.challenge?.underCommercials
              "
            >
              <app-card
                [accelerator]="accelerator"
                [type]="'challenge'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Under Draw Down  ({{
              deliveryList?.challenge?.underDrawDown?.count
            }})"
          >
            <div
              *ngFor="let accelerator of deliveryList?.challenge?.underDrawDown"
            >
              <app-card
                [accelerator]="accelerator"
                [type]="'challenge'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Under Implementation  ({{
              deliveryList?.challenge?.underImplementaation?.count
            }})"
          >
            <div
              *ngFor="
                let accelerator of deliveryList?.challenge?.underImplementaation
              "
            >
              <app-card
                [accelerator]="accelerator"
                [type]="'challenge'"
                [underImplementation]="'true'"
                [OpportunityFieldList]="OpportunityFieldList"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Completed ({{ deliveryList?.challenge?.completed?.count }})"
          >
            <div *ngFor="let accelerator of deliveryList?.challenge?.completed">
              <app-card
                [accelerator]="accelerator"
                [type]="'challenge'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Rejected ({{ deliveryList?.challenge?.rejected?.count }})"
          >
            <div *ngFor="let accelerator of deliveryList?.challenge?.rejected">
              <app-card
                [accelerator]="accelerator"
                [type]="'challenge'"
              ></app-card>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </mat-tab>
    <mat-tab label="Idea Pitch">
      <ng-template matTabContent>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="All ({{ deliveryList?.ideapitch?.all?.count }})">
            <div *ngFor="let accelerator of deliveryList?.ideapitch?.all">
              <app-card
                [accelerator]="accelerator"
                [type]="'ideapitch'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Under Commercials  ({{
              deliveryList?.ideapitch?.underCommercials?.count
            }})"
          >
            <div
              *ngFor="
                let accelerator of deliveryList?.ideapitch?.underCommercials
              "
            >
              <app-card
                [accelerator]="accelerator"
                [type]="'ideapitch'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Under Draw Down  ({{
              deliveryList?.ideapitch?.underDrawDown?.count
            }})"
          >
            <div
              *ngFor="let accelerator of deliveryList?.ideapitch?.underDrawDown"
            >
              <app-card
                [accelerator]="accelerator"
                [type]="'ideapitch'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Under Implementation  ({{
              deliveryList?.ideapitch?.underImplementaation?.count
            }})"
          >
            <div
              *ngFor="
                let accelerator of deliveryList?.ideapitch?.underImplementaation
              "
            >
              <app-card
                [accelerator]="accelerator"
                [type]="'ideapitch'"
                [underImplementation]="'true'"
                [OpportunityFieldList]="OpportunityFieldList"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Completed ({{ deliveryList?.ideapitch?.completed?.count }})"
          >
            <div *ngFor="let accelerator of deliveryList?.ideapitch?.completed">
              <app-card
                [accelerator]="accelerator"
                [type]="'ideapitch'"
              ></app-card>
            </div>
          </mat-tab>
          <mat-tab
            label="Rejected ({{ deliveryList?.ideapitch?.rejected?.count }})"
          >
            <div *ngFor="let accelerator of deliveryList?.ideapitch?.rejected">
              <app-card
                [accelerator]="accelerator"
                [type]="'ideapitch'"
              ></app-card>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
