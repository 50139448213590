<div style="display: flex; align-items: center; justify-content: space-between; padding: 10px;">
    <h6 style="width: 100%; font-size: 15px; margin-top: 10px; margin-left: 5px;" *ngIf=" type == 'challenge'">Challenge: {{accelerator?.statement?.title}} </h6>
    <h6 style="width: 100%; font-size: 15px; margin-top: 10px; margin-left: 5px;" *ngIf=" type == 'ideapitch'">Idea Pitch: {{accelerator?.ideapitch?.title}} </h6>
    <div style="display: flex; align-items: center" *ngIf="underImplementation == 'true'">
        <button mat-button color="oppo" (click)="add(accelerator?.statement?.title || accelerator?.ideapitch?.title)" class="mr-1">Create Opportunity</button>
        <button mat-button color="turnkey" (click)="createTrunkey()" class="ml-1">Create Plan</button>
    </div>
</div>
<div class="tab-holder">
    <div class="flex-container-1">
        <div class="flex-item p-1" *ngFor="let x of accelerator?.responses;">
            
            <div class="trn-x" [ngStyle]="{'background': x?.color}" (click)="navigateById('delivery-accelerator/operation/',x.source_id,x.id,x.status); $event.stopPropagation()">
                <div class="d-flex align-items-center">
                    <div>
                        <img *ngIf="x?.response.url" src="{{x?.response.url}}" style="width:30px;height:30px;border-radius: 50%;"/>
                        <span class="name-1" *ngIf="!x?.response.url"   [ngStyle]="{'backgroundColor': x?.response.logoColor}">{{getName(x?.response.provider_name)}}</span>
                    </div>
                    <h6 class="mb-0 trn-nm ml-2">{{x?.response?.provider_name}}</h6>
                </div>
                <p style="font-size: 10px;margin-top: 10px;">
                <i style="font-size:10px;margin-top: 10px;">Updated {{getLatestUpdated(x.last_updated_on)}}</i> </p>
                
                  
                <button *ngIf="x.status != 'rejected'" mat-button color="view" class="mt-1" (click)="navigateById('delivery-accelerator/operation/',x.source_id,x.id,x.status); $event.stopPropagation()">
                    View Response <br> Information
                </button> 
                
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'active'" style="background-color: green;">
                    Active
                </div>
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'under_commercials'" style="background-color: rgb(225, 58, 219);">
                    Under Commercials
                </div>
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'under_drawdown'" style="background-color: rgb(105, 128, 0);">
                    Under Drawdown
                </div>
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'under_implementation'" style="background-color: rgb(221, 208, 14);">
                    Under Implementation
                </div>
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'create_opportunity'" style="background-color: rgb(19, 121, 223);">
                    Create Opportunity
                </div>
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'create_turnkey'" style="background-color: rgb(226, 143, 9);">
                    Create Plan
                </div>
                <div class="st-col text-capitalize mt-3" *ngIf=" x.status == 'rejected'" style="background-color: rgb(143, 33, 5);">
                    Rejected
                </div>
            </div>
            <!-- <div class="dos">
                <app-tag [tagId]="[62]"></app-tag>

            </div> -->
        </div>
    </div>
</div>