import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ChallengeWorkbenchService } from "src/app/shared/services/challenge-workbench.service";
import { formatDistanceToNow } from "date-fns";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { ProgramService } from "src/app/shared/services/program.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  statements: any;
  statements1: any;
  statements2: any;
  statements3: any;
  deliveryAcceleratorList: any = [];
  deliveryAll: any = [];
  deliveryUnderCommercials: any = [];
  deliveryUnderDrawDown: any = [];
  deliveryUnderImplementation: any = [];
  deliveryUnderCompleted: any = [];
  deliveryReject: any = [];
  OpportunityFieldList: any;
  portList: any[] = [];
  programList: any[] = [];
  deliveryList: any;
  subscription: any;
  filterObj: any;
  tagFilterId: any;
  filterProvider: any = null;
  filterSector: any = null;
  filterName: any = null;
  filterVendor: any = null;
  filterType: any = null;
  providerList: any = [];
  sectorList: any = [];
  typeList: any = [];
  vendorsList: any = [];
  dataLoaded: boolean = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private cw: ChallengeWorkbenchService,
    private dialog: MatDialog,
    private ps: ProgramService,
    private filterService: FilterService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.tagFilterId = [];
        Object.keys(this.filterObj).forEach((element: any) => {
          if (element == "tag") {
            console.log(this.filterObj[element]);
            this.filterObj[element]?.array?.forEach((tag: any) => {
              this.tagFilterId.push(tag?.id);
            });
          }
        });
        this.search();
      }
    );
    this.getVendors();
    this.getOpportunity();
    this.getDeliveryAcceleratorList();
  }

  getVendors() {
    this.filterService.getVendor().subscribe((res: any) => {
      this.vendorsList = res;
    });
  }

  navigateTo(url: any) {
    this.router.navigateByUrl(url);
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 30%, 40%, 100)";
  }

  getDeliveryAcceleratorList() {
    this.spinner.show();
    this.dataLoaded = false;
    this.deliveryList = [];
    this.cw.getDeliveryAccelerator(this.tagFilterId).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.deliveryList = {
          challenge: this.constructDeliveryAcceleratorList(res?.challenge),
          ideapitch: this.constructDeliveryAcceleratorList(res?.ideapitch),
        };
        this.dataLoaded = true;
      },
      (error: any) => {
        this.spinner.hide();
        this.dataLoaded = true;
        throw error;
      }
    );
  }

  constructDeliveryAcceleratorList(list: any) {
    let allCount = 0,
      underCommercialsCount = 0,
      underDrawDownCount = 0,
      underImplementationCount = 0,
      completedCount = 0,
      rejectedCount = 0;
    let deliveryAcceleratorList = list;
    let deliveryUnderCommercials: any = [];
    let deliveryUnderDrawDown: any = [];
    let deliveryUnderImplementation: any = [];
    let deliveryUnderCompleted: any = [];
    let deliveryReject: any = [];
    list?.forEach((element: any) => {
      let underCommercialsResponse: any = [];
      let underDrawDownResponse: any = [];
      let underImplementationResponse: any = [];
      let completedResponse: any = [];
      let rejectedResponse: any = [];
      allCount = allCount + element.responses.length;

      element.responses.forEach((res: any) => {
        res.color = this.getGMapRandomColor();
        if (res.status == "under_commercials") {
          underCommercialsCount = underCommercialsCount + 1;
          underCommercialsResponse.push(res);
        }
        if (res.status == "under_drawdown") {
          underDrawDownCount = underDrawDownCount + 1;
          underDrawDownResponse.push(res);
        }
        if (res.status == "under_implementation") {
          underImplementationCount = underImplementationCount + 1;
          underImplementationResponse.push(res);
        }
        if (res.status == "create_opportunity") {
          completedCount = completedCount + 1;
          completedResponse.push(res);
        }
        if (res.status == "create_turnkey") {
          completedCount = completedCount + 1;
          completedResponse.push(res);
        }
        if (res.status == "rejected") {
          rejectedCount = rejectedCount + 1;
          rejectedResponse.push(res);
        }
      });
      if (underCommercialsResponse.length > 0) {
        if (element.statement) {
          deliveryUnderCommercials.push({
            statement: element.statement,
            responses: underCommercialsResponse,
          });
        } else if (element.ideapitch) {
          deliveryUnderCommercials.push({
            ideapitch: element.ideapitch,
            responses: underCommercialsResponse,
          });
        }
      }
      if (underDrawDownResponse.length > 0) {
        if (element.statement) {
          deliveryUnderDrawDown.push({
            statement: element.statement,
            responses: underDrawDownResponse,
          });
        } else if (element.ideapitch) {
          deliveryUnderDrawDown.push({
            ideapitch: element.ideapitch,
            responses: underDrawDownResponse,
          });
        }
      }
      if (underImplementationResponse.length > 0) {
        if (element.statement) {
          deliveryUnderImplementation.push({
            statement: element.statement,
            responses: underImplementationResponse,
          });
        } else if (element.ideapitch) {
          deliveryUnderImplementation.push({
            ideapitch: element.ideapitch,
            responses: underImplementationResponse,
          });
        }
      }
      if (completedResponse.length > 0) {
        if (element.statement) {
          deliveryUnderCompleted.push({
            statement: element.statement,
            responses: completedResponse,
          });
        } else if (element.ideapitch) {
          deliveryUnderCompleted.push({
            ideapitch: element.ideapitch,
            responses: completedResponse,
          });
        }
      }
      if (rejectedResponse.length > 0) {
        if (element.statement) {
          deliveryReject.push({
            statement: element.statement,
            responses: rejectedResponse,
          });
        } else if (element.ideapitch) {
          deliveryReject.push({
            ideapitch: element.ideapitch,
            responses: rejectedResponse,
          });
        }
      }
    });

    deliveryAcceleratorList.count = allCount;
    deliveryUnderCommercials.count = underCommercialsCount;
    deliveryUnderDrawDown.count = underDrawDownCount;
    deliveryUnderImplementation.count = underImplementationCount;
    deliveryUnderCompleted.count = completedCount;
    deliveryReject.count = rejectedCount;

    return {
      all: deliveryAcceleratorList,
      underCommercials: deliveryUnderCommercials,
      underDrawDown: deliveryUnderDrawDown,
      underImplementaation: deliveryUnderImplementation,
      completed: deliveryUnderCompleted,
      rejected: deliveryReject,
    };
  }

  getLatestUpdated(dateTime: any) {
    if (dateTime) {
      const timestamp = new Date(dateTime);
      return formatDistanceToNow(timestamp, { addSuffix: true });
    }
    return "";
  }
  navigateById(url: any, id: any) {
    this.router.navigate([url + btoa(id)]);
  }

  add() {
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      width: "700px",
    });
    dialog.componentInstance.type = "Addopperuntiy";
    dialog.componentInstance.OpportunityFieldList = this.OpportunityFieldList;
  }

  getOpportunity() {
    this.ps.getOpportunityFormData("new_opportunity").subscribe(
      (res: any) => {
        this.OpportunityFieldList = res.records;
        this.OpportunityFieldList.map((i: any) => {
          if (i.field_name == "opportunity_portfolio") {
            this.portList = i.field_values;
          }
        });

        if (
          this.OpportunityFieldList.findIndex(
            (e: any) => e.field_name == "program_name"
          ) < 0
        ) {
          let programdata = {
            id: 1,
            field_name: "program_name",
            display_name: "Program Name",
            lookup_field: "program_name",
            field_type: "text-box",
            field_status: true,
            model_reference: "''",
            field_values: this.programList,
            form_details: {
              id: 1,
              form_name: "program_name",
            },
          };

          this.OpportunityFieldList.push(programdata);
        }
        // this.showOpportunityBtn = true;
        // this.createForm();
      },
      (error) => {
        // this.toastrService.error('Opportunity fields get failed', 'Error');
        throw error;
      }
    );
    this.ps.getProgramData().subscribe(
      (res: any) => {
        this.programList = res;
      },
      (error) => {
        // this.toastrService.error('Opportunity fields get failed', 'Error');
        throw error;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  search() {
    const name = this.filterName ? this.filterName : "";
    const vendor = this.filterVendor ? this.filterVendor : "";
    const tags = this.tagFilterId ? this.tagFilterId : "";
    this.cw
      .getFilteredDeliveryAccelerator(name, vendor, tags)
      .subscribe((res: any) => {
        this.deliveryList = {
          challenge: this.constructDeliveryAcceleratorList(res?.challenge),
          ideapitch: this.constructDeliveryAcceleratorList(res?.ideapitch),
        };
      });
  }
}
